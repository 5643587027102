body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 20px;
}
.card {
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  text-align: center;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
